






















































import { FilterItem } from '@/interfaces/filter-items';
import MenuMixin from '@/mixins/menu-mixin';
import { mixins } from 'vue-class-component';
import { Vue, Component } from 'vue-property-decorator';
import TablePageMixin from '@/mixins/table-page-mixin';
import { list, columns } from './consts';
@Component({
  components: {}
})
export default class Todo extends mixins(MenuMixin, TablePageMixin) {
  columns = columns;
  list = list;
  activeKey = 1;

  created() {
    this.total = 100;
  }

  open() {}

  confirm(msg: string) {
    this.$confirm({
      title: '提示',
      content: msg,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {},
      onCancel: async () => {}
    });
  }

  reVisible: boolean = false;
  openMsg(type: number) {
    this.reVisible = true;
  }

  filterItems: FilterItem[] = [
    { label: '字典编码', key: 'alertCode', type: 'input' },
    { label: '字典名称', key: 'alertCode1', type: 'input' },
    { label: '字典类型', key: 'reviewDate', type: 'select', options: [] }
  ];

  filter: any = {};
  page = 1;
  excuteFilter(res: object) {
    this.page = 1;
    this.filter = res;
  }

  resetFilter() {
    this.page = 1;
    this.filter = {};
  }

  handleBatch() {}
}
